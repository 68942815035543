.dcm-pill-list {
  padding-left: 0;
  font-size: 14px;
  display: block;
}

.dcm-chart-container {
  margin: auto;
  min-width: 0;
  width: 60%;
}

.dcm-inline-block {
  display: inline-block;
}

.dcm-flex {
  display: flex;
}
