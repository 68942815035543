@media print {
  button {
    display: none !important;
  }
  h2 {
    float: left;
  }
  body {
    padding-left: 1.25in;
    padding-right: 1.25in;
  }
  .date-page-actions-container {
    margin-left: 0 !important;
    span {
      text-align: start !important;
    }
  }
  .info-for-print {
    display: initial !important;
  }
  section {
    page-break-inside: avoid;
    padding-top: 0.5in;
    margin-bottom: 0px !important;
  }

  .wc-table-header {
    font-size: 20px !important;
  }

  .wash-count-table.shrinked-table {
    td,
    th {
      font-size: 18px !important;
    }
  }
}
