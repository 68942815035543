.wash-counts {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.wc-main-container {
  padding: $section-padding;
  height: 100%;
  border-radius: $box-radius;
  background-color: #fff;
}

.wc-table-header {
  font-size: 18px;
}

.wc-table-container {
  margin-bottom: 20px;
  width: 100%;
}

.wash-chart-container {
  padding-left: 0;
}

.wc-site-name {
  font-size: 16px;
  font-weight: bold;
  margin-left: 12px;
}

.info-for-print {
  display: none;
}

.wash-chart {
  padding: 25px 0px 25px;
  text-align: center;
  border-radius: 3px;
  background-color: #fff;

  .wash-chart-label {
    font-size: 18px;
  }

  .hourly-counts-table-container {
    padding: 0 20px 0 20px;
  }
}

.home-page-hourly-chart {
  background-color: $tommy-gray-light;
}

.wash-chart.hourly-counts {
  padding: 20px;
  height: auto;

  .table {
    td {
      padding: 0.4rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .overview-buttons {
    .print-button {
      display: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .wash-counts {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 450px) {
  .overview-buttons {
    > * {
      flex-grow: 1;
    }
  }
}
