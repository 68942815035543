.tt-button-container {
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
  min-width: 137px;
}

.tt-label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  max-height: 100%;
  font-size: 75%;
  line-height: 15px;
  width: 100%;

  &:before {
    content: '';
    display: block;
    box-sizing: border-box;
    margin-top: 6px;
    border-top: solid 1px #d2d2d2;
    min-width: 10px;
    height: 8px;
    margin-right: 3px;
    border-left: solid 1px transparent;
    border-radius: 4px 0;
  }

  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    margin-top: 6px;
    border-top: solid 1px #d2d2d2;
    min-width: 10px;
    height: 8px;
    flex-grow: 1;
    margin-left: 3px;
    border-right: solid 1px transparent;
    border-radius: 0 4px;
  }
}

.tt-button-container-color {
  border: 1px solid #d2d2d2;
  border-radius: 0.25rem;
  border-top: 0;
  background: #ebebeb;
  padding: 9px;
}

.tt-button-margin {
  margin: 0.2em 0.3em 0 0.3em !important;
}

@media all and (max-width: 30em) {
  .tt-button-margin {
    display: block;
    margin: 0.4em auto !important;
    width: 90%;
  }
}
