@import '~bootstrap/scss/functions';
@import 'variables.scss';

.login-form-input {
  height: 4rem !important;
}

.login-error {
  text-align: center;
  margin: 1rem 0 0 0;
}

.form-signin {
  margin-bottom: 20px;
}

.login-button-text {
  padding: 7px;
}

.forgot-password-back {
  text-align: center;
  margin: 20px 0 0 0;
  cursor: pointer;
  color: $tommy-text-red;
  &:hover {
    text-decoration: underline;
  }
}

.login-button {
  margin-top: 2rem;
  border-radius: 6px !important;
  border: none;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  font-weight: 700;
  background: $login-button-red;
}

.login-button-text {
  padding: 7px;
}

.forgot-password-back {
  text-align: center;
  margin: 20px 0 0 0;
  cursor: pointer;
  color: $tommy-text-red;
  &:hover {
    text-decoration: underline;
  }
}

.login-input-label {
  display: block;
  font-weight: 700;
  font-size: 1.1rem;
  padding-top: 15px;
  text-align: left;
}
