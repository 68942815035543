.checkbox-mark {
  width: 20px !important;
  height: 20px !important;

  &::before {
    width: 20px !important;
    height: 20px !important;
  }

  &::after {
    font-size: large !important;
    left: 2px !important;
    top: -1px !important;
  }
}
