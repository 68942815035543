// Core variables and mixins
@import 'custom.scss';

@import '~bootstrap/scss/bootstrap';

// Override the new .btn-secondary class with an old one.
.btn-secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;

  &:hover,
  &:active,
  .show > &.dropdown-toggle,
  &:not(:disabled):not(.disabled):active {
    color: #292b2c;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad;
  }

  &.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  &:focus {
    outline: 0;
  }
}

.flex-1 {
  flex: 1;
}
