@keyframes ellipsis {
  to {
    width: 1.2em;
  }
}

.el-loader {
  font-size: 30px;
  white-space: nowrap;

  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(5, end) 2s infinite;
    content: '\2026';
    width: 0px;
  }
}
