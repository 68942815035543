.manage-users-table {
  tr {
    height: 3rem;
    cursor: pointer;
  }

  td {
    vertical-align: middle !important;
  }
}
