.drawer-container {
  display: flex;
  & > * {
    transition: 0.3s all ease;
  }
  & > *:not(.drawer) {
    flex: 1 1 auto;
    width: auto;
    min-width: 0;
    overflow: hidden;
  }
  .drawer {
    flex-basis: 0%;
    overflow: hidden;
    opacity: 0;
    & > .drawer-content {
      max-height: 0;
      opacity: 0;
      transition: 0.1s all ease;
    }
    &.expanded {
      flex-basis: auto;
      opacity: 1;
      flex-shrink: 0;
      flex-grow: 1;
      & > .drawer-content {
        max-height: 1000px;
        opacity: 1;
        transition: 0.5s 0.2s all ease;
      }
    }
    &.maximized {
      flex-basis: 100%;
    }
    &.expanded:not(.maximized) {
      margin-left: 1.25rem;
    }
    &:first-child.expanded:not(.maximized) {
      margin-left: 0;
      margin-right: 1.25rem;
    }
  }
}

@media screen and (max-width: 700px) {
  .drawer {
    &.expanded {
      flex-basis: 100% !important;
      margin: 0 !important;
    }
    .drawer-controls {
      .drawer-maximize {
        display: none;
      }
    }
  }
}
