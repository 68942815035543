.expenseDetail {
  &--gridActionButtons {
    grid-area: 1 / 5 / 2 / 5;
    height: 24px;
    align-self: start;
  }

  &--infoHeader {
    grid-area: 2 / 1 / 2 / 1;
    font-weight: bold;
    font-style: italic;
  }

  &--orderedBySection {
    grid-area: 3 / 1 / 3 / 1;
  }

  &--vendorSection {
    grid-area: 3 / 2 / 3 / 2;
  }

  &--categorySection {
    grid-area: 4 / 1 / 4 / 1;
  }

  &--trackingSection {
    grid-area: 4 / 2 / 4 / 2;
  }
}

.fontSize16 {
  font-size: 16px;
}

.minWidth140 {
  min-width: 140px;
}

.table-container {
  border: 1px solid black;
  border-radius: 6px;
  background-color: #fff;
}

.table-caption {
  caption-side: top;
  border-bottom: 1px solid black;
  text-align: center;
  font-size: 15px;
  padding: 5px 0px;
}

.standard-row {
  font-size: 13px;
  margin: 0px 5px;
}
