.landing-page-header-container {
  text-align: center;
  margin-bottom: 2rem;
}

.landing-page-header-logo {
  width: 100%;
  max-width: 250px;
  height: auto;
}

.landing-page-header-text {
  margin-top: 2rem;
  font-weight: 800;
  font-family: Avenir Next;
}
