.dr-multi-select-cell {
  background: #fff !important;
  width: 45px;
  border-right: 1px solid #dee2e6;
  vertical-align: middle !important;

  &:hover {
    background: #ffffffbf !important;
  }
}

.dr-multi-select-header {
  width: 45px;
  border-right: 1px solid #dee2e6;
  padding: 0 !important;
  vertical-align: middle !important;
}

.dr-styled-checkbox {
  display: none;

  & + label {
    position: relative;
    margin: auto;
    width: 35px;
    height: 35px;
  }

  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    background: #fff;
    border: 2px solid #cccccc;
    border-radius: 5px;
    color: transparent;
    transition: 0.2s;
  }

  & + label:active:before {
    transform: scale(0);
  }

  &:checked:hover + label:before,
  &:indeterminate:hover + label:before {
    background: $checkmark-green;
    border-color: $checkmark-green;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before,
  &:indeterminate + label:before {
    background-color: $checkmark-green;
    border-color: $checkmark-green;
    color: #fff;
  }

  &:disabled + label {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '\2713';
    font-size: xx-large;
    position: absolute;
    left: 4px;
    top: -8px;
    color: #fff;
    width: 2px;
    height: 2px;
  }

  &:indeterminate + label:after {
    content: '\2013';
    font-size: xx-large;
    position: absolute;
    left: 9px;
    top: -8px;
    color: #fff;
    width: 2px;
    height: 2px;
  }
}

.dr-button {
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem;
}

.dr-word-wrap {
  white-space: pre-wrap;
  word-break: break-word;
}

.dr-row {
  background: #fff;
  transform: translate(0);
  &.highlighted,
  &.highlighted:hover {
    background: hsla($green-hue, 100%, 45%, 0.1) !important;
  }
}

.dr-actions {
  padding: 0;
  min-width: 85px;
}

.dr-extra-data-row {
  transform: translate(0);
}

.dr-extra-data-cell {
  border-top: none;
}

.dr-extra-data-header {
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  top: -3px;
}

.dr-old-price {
  color: darkgrey;
}

.dr-loader-container {
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: none !important;
  position: absolute;
  padding: 3px;
  opacity: 0.9;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dr-row-loader {
  height: 55px;
}

.td-padded-first-column {
  padding-left: 32px !important;
}

.archived {
  opacity: 0.4;
}
