//
// Icon nav
//
$gray-dark: #292b2c;
$gray-light: #4a4a4a;
$iconav-bg: $gray-light;
$iconav-width: 80px;
$iconav-hide-width: 20px;
$iconav-brand-color: inherit;
$iconav-brand-hover-color: inherit;
$iconav-brand-bg: $gray-dark;
$iconav-link-color: #fff;
$iconav-link-border: $gray-dark;
$iconav-link-active-bg: #fff;

.iconav {
  background-color: $iconav-bg;
  @include media-breakpoint-up(sm) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: $iconav-width;
    margin-top: 0;
    margin-bottom: 0;
    overflow-y: auto;
    -webkit-transform: translate3d(0, 0, 0); // Make the sidebar scrollable at all times in Safari
  }
}

.iconav-hide {
  @extend .iconav;
  width: 20px !important;
}

// Account for the width of the iconav
.with-iconav > .container,
.with-iconav > .container-fluid {
  position: static;
  padding-bottom: 2rem;
  &.nopadding {
    padding-left: $iconav-width;
    padding-bottom: 0;
    padding-right: 0;
  }
  @include media-breakpoint-up(sm) {
    padding-left: $iconav-width + $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

.with-iconav-nav-hidden > .container,
.with-iconav-nav-hidden > .container-fluid {
  position: static;
  padding-bottom: 2rem;
  &.nopadding {
    padding-left: $iconav-hide-width;
    padding-bottom: 0;
    padding-right: 0;
  }
  @include media-breakpoint-up(sm) {
    padding-left: $grid-gutter-width + $iconav-hide-width;
    padding-right: $grid-gutter-width;
  }
}

@media screen and (max-width: 575px) {
  .with-iconav > .container-fluid,
  .with-icon-nav-hidden > .container-fluid {
    &.nopadding {
      padding-left: 0 !important;
    }
  }
}

.iconav-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  font-size: 18px;
  line-height: 1;
  color: $iconav-brand-color;
  text-align: center;
  border-bottom: 0;
  background-color: $iconav-brand-bg;

  &:hover {
    color: $iconav-brand-hover-color;
    text-decoration: none;
  }
}

.nav-hidden {
  display: none;
}

.nav-hide-button {
  left: 4px;
}

.nav-show-button {
  left: 66px;
}

.nav-button {
  position: fixed;
  background-color: #292b2c;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  top: 24px;
  color: #e6e6e6;
  border: 1px solid #e6e6e6;
  background-image: none;
  padding: 0;
  z-index: 10;
  @media (max-width: 567px) {
    position: initial !important;
  }
}

.iconav-brand-icon {
  font-size: 30px;
  color: #fff;
}

.iconav-nav {
  display: block;
  // For unread counts and the like
  .label {
    display: block;
    font-size: 11px;
    white-space: normal;
  }

  // Nav items
  > li {
    float: none;
    display: inline-block;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    > a {
      position: relative;
      display: block;
      padding: 10px 12px;
      font-size: 18px;
      color: $iconav-link-color;
      text-align: center;
      border-radius: 0;

      &:hover,
      &.active {
        background-color: var(--brandPrimary);
        text-decoration: none;
      }

      @include media-breakpoint-up(sm) {
        padding: 15px 5px;
      }
    }

    + li {
      margin-left: -3px;

      @include media-breakpoint-up(sm) {
        margin-left: 0;
        > a {
          border-top: 1px solid $iconav-link-border;
          border-right: 0;
        }
      }
    }
  }
}

// Slider nav
//
// Move from a horizontal slider nav like iOS's tab bar to a stacked nav easily.

// Show text labels for xs viewports (uses inline utility classes for toggling visibility)
.iconav-nav-label {
  font-size: 11px;
}

// Make it slide
@include media-breakpoint-down(sm) {
  .iconav-slider {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
}
