.table-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;

  &.no-break {
    grid-template-columns: auto auto;
  }

  .form-group {
    margin-top: 10px;
    margin-right: 5px;
    margin-bottom: 0;
  }

  .filters {
    display: flex;
  }

  .page-action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.5rem;
  }
}
@media (max-width: 620px) {
  .table-buttons {
    grid-gap: 10px;
    grid-template-columns: 1fr;

    .form-group {
      flex: 1;
      margin: 0;

      &:first-child {
        margin-right: 5px;
      }
    }
  }
}
.filters-actions-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  flex-grow: 1;
}
.table-filters {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 6px;
  .form-group {
    margin: 0;
  }
}

//Move elements to 50% width
@media screen and (max-width: 1000px) {
  .table-filters,
  .table-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

//Stack buttons to a single column
@media screen and (max-width: 600px) {
  .table-filters {
    margin-top: 0.5rem;
  }
  .table-buttons:not(.no-break) {
    grid-template-columns: 1fr;
    .page-action-buttons {
      * {
        flex-grow: 1;
      }
    }
  }
}

//Stack filters to a single column
@media screen and (max-width: 400px) {
  .table-filters {
    grid-template-columns: 1fr;
    .data-table-search {
      margin-top: 0.5rem;
    }
  }
  .table-buttons {
    .page-action-buttons {
      flex-wrap: wrap;
      * {
        flex-basis: 100%;
      }
    }
  }
}
