.f-popover-show {
  position: absolute;
  margin-top: 6px;
  z-index: 2;
  opacity: 1;
  transform: none;
  transition: opacity 262ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 174ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform-origin: center top;
}

.f-popover-hide {
  position: absolute;
  z-index: 2;
  opacity: 0;
  will-change: transform;
  transform: scale(0.75, 0.5625);
  transform-origin: 79px 0px;
  transition: opacity 262ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 174ms cubic-bezier(0.4, 0, 0.2, 1) 87ms;
}
