.redux-toastr {
  .top-right {
    min-width: 350px;
    width: fit-content;
    max-width: 100%;
  }

  .rrt-middle-container {
    overflow: hidden;
  }
}

.error-notification {
  text-overflow: ellipsis;
  overflow: hidden;
}
