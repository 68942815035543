.adjust-wash-count-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  font-size: 12px;

  .adjustment-rows-grid {
    .adjustment-rows-headers-grid {
      display: grid;
      grid-template-columns: repeat(5, 20%);
      text-align: center;
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 10px;

      .adjustment-rows-header {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
      }
    }

    .adjustment-row {
      display: grid;
      grid-template-columns: repeat(5, 20%);
      text-align: center;
      padding: 5px 0;
      transform: translate(0);

      .adjustment-row-number {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .adjustment-row-number-input {
        max-width: 50px;
        justify-self: center;
      }

      input,
      select {
        text-align: center;
        padding: 0.1rem 0.1rem;
        font-size: 12px !important;
        height: 30px;
        align-self: center;
        border: none;
        background: #ededed;
      }

      .adjustment-row-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -7px;
        top: 10px;

        .adjustment-row-action-container {
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          &.cross {
            $danger-color: #{map-get($theme-colors, danger)};
            background: $danger-color;
            @include button-variant(#dc3545, #dc3545);
          }
        }
      }
    }

    .add-adjustment-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 0.5rem;

      .add-adjustment-row-button {
        grid-column: 1/3;
        cursor: pointer;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .adjustment-notes {
    label {
      font-weight: bold;
    }
  }
}

@media (max-width: 991px) {
  .adjust-wash-count-form-grid {
    grid-template-columns: 1fr;
  }
}
