.wash-club-login-container {
  background-image: url('../images/wash_club_login_bg.jpeg');
  background-size: cover;
  overflow: hidden;
  height: 100%;
}
.login-sidebar {
  box-shadow: 0px 0px 8px 1px;
  background-color: #ffffff;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 500px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.register-dialog {
  box-shadow: 0px 0px 8px 1px;
  background-color: #ffffff;
  overflow-y: auto;
  margin: auto;
  height: auto;
  width: 500px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  animation: show 0.75s ease-in;
}

@media (max-width: 830px) {
  .login-sidebar {
    width: 100%;
  }

  .register-dialog {
    width: 100%;
  }
}

@media (max-height: 845px) {
  .register-dialog {
    height: 100%;
  }
}

@keyframes show {
  from {
    visibility: hidden;
    transform: translateY(-50%);
  }
  to {
    transition: visibility 0.2s linear 0.3s, opacity 0.3s 0.3s, transform 0.3s;
  }
}

// Tommy Manager Login

/* Login Container */
.login-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 2rem;
}

/* Background Slideshow */
.background-slideshow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Individual background slide */
.background-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

/* Animate the slideshow */
@keyframes background-slideshow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.background-slide:nth-child(1) {
  animation: background-slideshow 24s infinite;
  animation-delay: 0s;
}

.background-slide:nth-child(2) {
  animation: background-slideshow 24s infinite;
  animation-delay: 8s;
}

.background-slide:nth-child(3) {
  animation: background-slideshow 24s infinite;
  animation-delay: 16s;
}

.background-slide:nth-child(4) {
  animation: background-slideshow 24s infinite;
  animation-delay: 24s;
}

/* Login Form */
.login-form {
  max-width: 400px;
  max-height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  overflow-y: auto;
}

.login-form h2 {
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.login-form .form-group {
  margin-bottom: 15px;
  text-align: left;
}

.login-form .form-group label {
  display: block;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.login-form .form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-input-remove-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
