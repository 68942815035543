@import '~bootstrap/scss/functions';
@import 'variables.scss';

.forgot-password-form-input {
  height: 4rem !important;
}

.forgot-password-error {
  text-align: center;
  margin: 1rem 0 0 0;
}

.forgot-password-back {
  text-align: center;
  margin: 20px 0 0 0;
  cursor: pointer;
  color: var(--brandText);
  &:hover {
    text-decoration: underline;
  }
}

.forgot-password-button-text {
  padding: 7px;
}

.forgot-password-button {
  margin-top: 2rem;
  border-radius: 6px !important;
  border: none;
  width: 100%;
  padding: 10px;
  color: #ffffff;
  font-weight: 700;
  background: var(--brandGradient);

  &:disabled {
    opacity: 0.4;
  }

  &:enabled {
    &:hover {
      filter: brightness(1.1);
    }
  }
}

.forgot-password-input-label {
  font-weight: 700;
  font-size: 1.25rem;
  padding-top: 15px;
}
