.fm-addBarInput {
  display: inline-block;
  width: 77%;
  margin-right: 3px;
  vertical-align: top;
}

.fm-addBarButton {
  width: 19%;
  vertical-align: top;
  border-radius: 0.3rem;
  height: 30px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.fm-changeLogRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  height: 50px;
}

.fm-changeText-wrap {
  white-space: pre-wrap;
  margin-top: revert;
  margin-bottom: revert;
}

.fm-float {
  &-right {
    float: right;
  }

  &-left {
    float: left;
  }
}

.fm-changeLogButton {
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.fm-changeLogMenu {
  max-height: 299px;
  overflow: hidden;
  width: 460px;
}

.fm-changeLogMenuBody {
  max-height: 200px;
  overflow: scroll;
}

.fm-margin-auto {
  margin: auto;
}

.fm-focusSelect-buttonGroup {
  width: 100%;
  margin-bottom: 2px;
}

.fm-focusSelect-focusButton {
  width: 75%;
  display: inline-block;
  margin-top: 0px;
  background-color: #fff;
  color: #292b2c;
  border-color: #ccc;
  box-shadow: none;
}

.fm-focusSelect-focusButton-active {
  @extend .fm-focusSelect-focusButton;
  background-color: #5a6268;
  color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.fm-focusSelect-borderRadius {
  border-bottom-right-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.fm-focusSelect-container {
  background-color: rgba(0, 0, 0, 0.03);
  width: 185px;
}

.fm-focusSelect-optionContainer {
  height: 530px;
  overflow: scroll;
  margin-top: 3px;
}

.fm-focusWindow-apply {
  float: right;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.fm-display-flex {
  display: flex;
}

.fm-inline-block {
  display: inline-block;
}

.fm-focusWindow-divider {
  border: 0px;
  height: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.fm-focusWindow-container {
  margin-left: 40px;
  margin-right: 40px;
}

.fm-focusWindow-content {
  width: 240px;
  float: right;
  margin-bottom: 28px;
}

.fm-focusWindow-grayBox {
  padding: 5px;
  background: #d2d2d2;
}

.fm-container {
  min-height: 530px;
  max-height: 633px;
  overflow: hidden;
}

.fm-content {
  float: left;
  width: 210px;
  vertical-align: top;
  margin-right: 17px;
}

.fm-windowWrapper {
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
}

@media (max-width: 749px) {
  .fm-content {
    float: none;
    margin-right: 0px;
    width: 100%;
  }

  .fm-focusWindow-container {
    margin-left: 20px;
    margin-right: 20px;
  }

  .fm-focusSelect-optionContainer {
    height: 70px;
    overflow: auto;
    white-space: nowrap;
    margin-top: 3px;
    margin-bottom: 15px;
  }

  .fm-focusSelect-buttonGroup {
    width: auto;
  }

  .fm-focusSelect-container {
    width: auto;
  }

  .fm-changeLogMenu {
    width: 290px;
  }
}
