.drs-calendar-inputs {
  max-width: 118px;
}

.drs-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  z-index: 2;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.drs-button-shadow {
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.drs-button-override {
  color: #fff;
  background-color: var(--brandPrimary) !important;
  border-color: var(--brandPrimary) !important;
}

.drs-button-active {
  color: #fff;
  background-color: var(--brandPrimary);
  border-color: var(--brandPrimary);
}

.drs-custom-range-section {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1.25rem;
}

.drs-date-operation-dropdown {
  max-width: 158px;
  vertical-align: top;
  display: inline-block;
}

.drs-divider {
  margin: 0 7px;
  width: 1px;
  height: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0.14);
  display: inline-block;
}

.drs-action-button {
  float: right;
  margin: 0.25rem;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.drs-calendar-and {
  float: left;
  margin: 7px 6px 4px 6px;
}

.drs-container {
  position: relative;
  max-width: 245px;
}

.drs-card-header-title {
  width: 80px;
  display: inline-block;
}

.drs-body-padding {
  padding-top: 12px;
  padding-bottom: 5px;

  &--footer {
    padding: 8px 12px 11px 12px;
  }
}

.drs-date-input-container {
  display: inline-block;
  width: 285px;
  vertical-align: top;
}

.drs-date-range-padding {
  padding: 10px 0px;
}

.drs-loader-container {
  overflow: hidden;
  height: 149px;
}

.drs-loader {
  margin: auto;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.271);
  padding: 53px 98px;
  overflow: hidden;
  height: 149px;
  border: 1px solid rgba(187, 175, 175, 0.3);
  border-radius: 6px;
  color: #ffffffd9;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.drs-flex {
  display: flex;
}

.drs-h-39 {
  height: 39px;
}

.drs-pb-12 {
  padding-bottom: 12px;
}

.drs-margin-25 {
  margin: 0.25em;
}

.drs-mr-7 {
  margin-right: 7px;
}

.drs-quickselect-mh {
  margin: 5px;
  height: 52px;
}

.drs-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.second-datetime-picker {
  float: left;
}

@media screen and (max-width: 415px) {
  .drs-card {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 315px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    z-index: 2;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }

  .card-header {
    padding: 0.15rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .drs-date-input-container {
    display: grid;
    width: 285px;
  }

  .second-datetime-picker {
    display: inline-block !important;
    float: none;
  }

  .drs-custom-range-section {
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.75rem;
    height: 210px;
  }

  .drs-body-padding {
    padding-top: 5px;
    padding-bottom: 5px;

    &--footer {
      padding: 3px 75px 1px 2px;
    }
  }
}
