@import './variables.scss';

.user-form-row {
  flex-wrap: nowrap !important;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.user-form-col {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.site-selector-input {
  padding-right: 2.25rem !important;
}

.site-selection-with-icon {
  transform: translate(0);

  i {
    position: absolute;
    top: 0;
    right: 10px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 1.25rem;
  }
}

.selection-container {
  transform: translate(0);
  z-index: 3;
}

.selected-sites-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  width: 100%;
  padding-bottom: 0.5rem;

  .selected-site {
    flex: 1;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    min-width: 0;
    background-color: $remove-site-button;
    border: solid 1px $remove-site-button;
    border-radius: 6px;
    color: white;

    .site-name {
      text-align: center;
      white-space: nowrap;
      text-align: start;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 0.5rem;
    }

    i {
      display: flex;
      font-size: 2rem;
      padding: 0 0.5rem;
      transition: transform 0.15s ease-in-out;
      height: 100%;
      align-items: center;
      cursor: pointer;

      &:hover {
        transform: scale(1.3);
      }
    }

    .icon::before {
      position: static !important;
    }
  }

  @media (max-width: 1199px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.suggested-site-list-container {
  position: absolute;
  width: 100%;
  background-color: white;
}

.suggested-site-list {
  position: relative;
  margin-top: 2px;
  margin-bottom: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: white;
  list-style-type: none;
  padding-left: 0;
  max-height: 200px;
  overflow-y: auto;

  .list-item {
    text-align: start;
    padding: 0.375rem 0.75rem;
    width: 100%;
    background-color: white;
    border: none;

    &:not(.not-found) {
      cursor: pointer;

      &:hover {
        background-color: $tommy-gray-light;
      }
    }
  }
}

.site-selection-column {
  display: flex;
  flex-direction: column;
}
