@import './variables.scss';

.form-button {
  padding: 0 1.5rem;
  border-radius: 6px;
  transition: $color-transition;
  background-color: white;
  @media (max-width: 767px) {
    flex: 1;
    padding: 0;
  }
  height: 3rem;
}

.go-back-button {
  @extend .form-button;

  color: $clear-fields-red;
  border: 2px solid $clear-fields-red;

  &:hover {
    background-color: $clear-fields-red;
    color: white;
  }
}

.clear-fields-button {
  @extend .form-button;

  color: $clear-fields-red;
  border: 2px solid $clear-fields-red;
  p &:disabled {
    background-color: white;
    color: $clear-fields-disabled;
    border-color: $clear-fields-disabled;
  }

  &:enabled {
    &:hover {
      background-color: $clear-fields-red;
      color: white;
    }
  }
}

.next-page-button {
  @extend .form-button;

  color: $next-page-blue;
  border: 2px solid $next-page-blue;

  &:disabled {
    background-color: white;
    color: lighten($color: $next-page-blue, $amount: 20);
    border-color: lighten($color: $next-page-blue, $amount: 20);
  }

  &:enabled {
    &:hover {
      background-color: $next-page-blue;
      color: white;
    }
  }
}

.confirm-button {
  @extend .form-button;

  color: $confirm-blue;
  border: 2px solid $confirm-blue;

  &:hover {
    background-color: $confirm-blue;
    color: white;
  }
}

.cancel-button {
  @extend .form-button;

  color: $cancel-red;
  border: 2px solid $cancel-red;

  &:hover {
    background-color: $cancel-red;
    color: white;
  }
}

.archive-user-button {
  @extend .form-button;

  color: $archive-user-red;
  border: 2px solid $archive-user-red;

  &:enabled {
    &:hover {
      background-color: $archive-user-red;
      color: white;
    }
  }
}

.success-container {
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.checkmark-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '';
  background-color: $checkmark-green;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.checkmark:after {
  content: '\2713';
  font-size: 5rem;
  color: white;
}

.instructions {
  margin-top: 1.5rem;
  font-size: 2rem;
  text-align: center;
}
