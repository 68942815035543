//Button variables
$button-padding-top: 0.4em;
$button-padding-bottom: 0.5em;
$button-padding-sides: 0.75em;
$button-radius: $box-radius;
$transition-time: 0.25s;

//Remove button element border style outset
button {
  border-style: none;
}

.button,
.file-upload label {
  --bgColor: rgb(222, 222, 225);
  --bgHover: rgb(210, 210, 212);
  display: inline-block;
  padding: $button-padding-top $button-padding-sides $button-padding-bottom $button-padding-sides;
  border-radius: $button-radius;
  transition: $transition-time background-color ease, $transition-time color ease;
  background-color: var(--bgColor);
  text-decoration: none;
  border: 1px solid transparent;
  font-size: inherit;
  color: inherit;
  //Using custom properties mapped to SASS variables
  &.primary {
    --bgColor: var(--brandPrimary);
    --bgHover: var(--brandPrimaryHover);
  }
  &.cancel {
    --bgColor: #{$tommy-red};
    --bgHover: #{desaturate(lighten($tommy-red, 10), 5)};
  }
  &.white {
    --bgColor: white;
    --bgHover: white;
  }
  &.transparent {
    --bgColor: transparent;
  }
  &.green,
  &.confirm {
    --bgColor: hsl(#{$green-hue}, 75%, 45%);
    --bgHover: #{desaturate(lighten(hsl($green-hue, 75%, 45%), 10), 10)};
  }
  &.blue {
    --bgColor: #{$tommy-blue};
    --bgHover: #{lighten($tommy-blue, 5)};
  }
  //Add new color variations above this line, order matters with sass
  &:hover {
    background-color: var(--bgHover);
    border-color: transparent;
    text-decoration: none;
    color: inherit;
  }
  &:disabled {
    background-color: var(--bgColor);
    border-color: transparent;
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:visited {
    color: inherit;
  }
  &.inverted {
    transition: 0.1s background-color ease, 0.1s color ease;
  }
  /*
    Most colored buttons should have white text and different behavior
    Add new button color classes to this section
    */
  &.primary,
  &.green,
  &.blue,
  &.cancel,
  &.confirm {
    color: white;
    &.inverted {
      color: var(--bgColor);
      background: transparent;
      border: 1px solid var(--bgColor);
      &:hover {
        color: white;
        border: 1px solid transparent;
        background-color: var(--bgColor);
      }
    }
  }
  &.inverted {
    color: black;
    background: transparent;
    border: 1px solid black;
    &:hover {
      color: white;
      border: 1px solid transparent;
      background-color: black;
    }
  }
  &.gradient {
    color: white;
    background: var(--brandGradient);
    border: none;
    transition: $transition-time filter ease;
    &:hover {
      filter: brightness(1.1);
    }
  }
  &.rounded {
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 2em !important;
  }
  &.full-width {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  &.bold {
    font-weight: bold;
  }
  &.small {
    font-size: 0.75rem;
  }
  &.large {
    font-size: 1.4rem;
  }
  &.settings-button {
    width: 100%;
    max-width: 150px;

    @media screen and (max-width: 600px) {
      &.settings-button {
        max-width: 100%;
      }
    }
  }
}
