.permission-summary {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;
  min-height: 0;
}

.permission-summary-item-list {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-auto-rows: 50px;
  gap: 5px;
  min-height: 0;
  overflow-y: auto;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  transform: translate(0);
}

.permission-summary-item {
  color: white;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
}

.permission-name {
  display: flex;
  align-items: center;
  background: black;
  cursor: pointer;
  text-align: center;
  padding: 5px 1rem;
  border-radius: 6px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  justify-content: space-between;
  height: 100%;

  span {
    text-align: start;
  }

  &:hover {
    background-color: lighten($color: black, $amount: 70);
  }
}
