.dt-table-scroll {
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
  overflow: auto;
  max-height: 805px;
  border: 1px solid $border-color;
  border-radius: $box-radius;

  & > table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0;
  }

  & > table th {
    border-top: none;
  }

  & > table > thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: $tommy-gray-light;
    font-size: 14px;
    padding-left: 0.5rem;
  }
}

.dt-sticky-search {
  position: sticky;
  top: 45px;
  z-index: 1;
  background: $tommy-gray-light;
  border-bottom: 1px solid #dee2e6;
  border-top: 0px;
}

.pagination {
  overflow: auto;
  .page-link {
    color: black;
    border: 1px solid $border-color;
    &:hover {
      z-index: 0;
    }
  }
  .page-item.active {
    .page-link {
      background-color: var(--brandPrimary);
      border-color: var(--brandPrimary);
      z-index: 1;
    }
  }
}
