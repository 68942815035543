.powerbi-iframe {
  width: 100%;
  height: 82vh !important;
  border: hidden !important;
}

.powerbi-container-fluid {
  padding-left: 88px !important;
  padding-right: 11px !important;
}

.powerbi-left-buttons {
  display: inline-block;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 6px 27px;
  &:focus {
    outline: none;
  }
}

.powerbi-right-buttons {
  display: inline-block;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  padding: 6px 27px;
  &:focus {
    outline: none;
  }
}

.powerbi-button-container {
  display: flex;
  justify-content: center;
  margin-top: -11px;
}

@media (max-width: 768px) {
  .powerbi-button-container {
    margin-top: -12px;
    margin-bottom: 10px;
  }
}

@media (max-width: 375px) {
  .powerbi-button-container {
    margin-top: -12px;
    margin-bottom: 10px;
  }
  .powerbi-container-fluid {
    padding-left: 15px !important;
  }
}

.powerbi-container {
  padding: 0px !important;
  margin-top: 13px !important;
}

.powerbi-tab-container {
  display: flex;
  overflow-x: scroll;
}

.powerbi-tab-scroll {
  white-space: nowrap;
  margin: 0 auto;
}

.powerbi-active {
  color: $tommy-gray-light !important;
  background-color: var(--brandPrimary) !important;
  border-color: var(--brandPrimary) !important;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  &:hover {
    background-color: var(--brandPrimaryHover);
  }
}

.powerbi-inactive {
  transition: box-shadow 0.03s ease-in;
  color: #292b2c;
  background-color: $tommy-gray-light;
  border-color: $tommy-gray-light;
  &:hover {
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.3);
    background-color: #e6e6e6;
    border-color: #d6d6d6;
  }
}

.powerbi-tabs {
  background-color: $tommy-gray-light;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom: none;
  border-color: #e6e6ea;
  color: #292b2c;
  text-align: center;
  vertical-align: middle;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 1.5;
  display: inline-block;
  margin-right: 3px;
  outline: none;
  &:focus {
    outline: none;
    border: none;
  }
}
.powerbi-error {
  background-color: white;

  .powerbi-loading {
    margin-top: 10px;
  }
}
