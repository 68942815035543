.n-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  position: sticky;
  top: 0;
  box-shadow: $box-shadow;
  min-height: 79px;
  padding: 1rem $main-padding;
  gap: 1rem;
  z-index: 2;
}
.sites-container {
  .overview-chart-card {
    padding-bottom: 0.5rem !important;
    .card-header {
      padding: 0.75rem 1.25rem !important;
    }
  }
}
.nav-mobile-button {
  display: none;
}
.sites-text {
  font-size: 10px;
  margin-left: 10px;
}
.mobile-status-container {
  display: none;
}
@media screen and (max-width: 700px) {
  .mobile-status-container {
    display: block;
  }
  .desktop-status-container {
    display: none;
  }
}
.status-container {
  position: relative;
}
.status-button:focus {
  color: black;
  background-color: #e6e6e6;
  box-shadow: none;
}
.status-link {
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  color: #c1c1c1;
  padding-right: 10px;
  padding-left: 10px;
  text-decoration: underline;
}

.status-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
}

.status-circle.green {
  background-color: #00d563;
}

.status-circle.red {
  background-color: $tommy-red;
}

.status-mobile {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  line-height: 16px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: white;
  z-index: 5;
}

.status-mobile.green {
  background-color: #00d563;
}

.status-mobile.red {
  background-color: $tommy-red;
}

.n-site-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  flex: 1;
  margin: 0;
  border-radius: 20px;
}

.sites-button {
  border-radius: 20px;
  display: none !important;
}

.n-logo {
  height: 100%;
  width: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.2rem;
}

.n-section {
  height: 100%;
}

.n-menus {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.n-margin-right {
  margin-right: 1rem;
}

.n-main-dropdown {
  z-index: 4;
}

.n-dropdown-toggle {
  display: inline-block;
}

.n-dropdown-span {
  margin-left: 0.5rem;
}

.zendesk-chat-button {
  padding-right: 1rem;
}

.n-support-button {
  background-color: var(--brandPrimary);
  border: none;
  border-radius: 999rem;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.n-support-icon {
  fill: white;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.n-support-text {
  color: white;
  margin-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.n-menus button {
  border-radius: 4px;
}

.custom-tx-select {
  width: fit-content;
  min-width: 400px;
  max-width: 100%;
  .custom-tx__value-container {
    padding: 5px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  .custom-tx__control--is-focused {
    border-color: transparent !important;
    box-shadow: 0 0 0 1px black !important;
  }
  .custom-tx__menu {
    width: 100%;
    max-width: 100%;
  }
  .custom-tx__input-container {
    /*margin-left: 4px;*/
  }
  .custom-tx__indicators::before {
    content: '';
    width: 15px;
    height: calc(100% - 5px);
    top: 0;
    margin-left: -14px;
    position: absolute;
    background: linear-gradient(90deg, transparent, white);
    z-index: 1;
  }
  .custom-tx__multi-value {
    flex-shrink: 0;
    border-radius: $box-radius;
    cursor: pointer;
    margin: 3px;
    * {
      cursor: pointer;
    }
    .custom-tx__multi-value__label {
      padding: 0.4em 0.7em;
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
    .custom-tx__multi-value__remove {
      padding: 0 0.4em;
      border-radius: 0 $box-radius $box-radius 0;
    }
  }
  .custom-tx__multi-value:first-of-type() {
    background-color: var(--brandPrimary);
    color: white;
    cursor: inherit;
    .custom-tx__multi-value__label {
      color: white;
      max-width: none;
      overflow: auto;
    }
  }
}

.custom-tx--is-disabled {
  width: fit-content;
  min-width: 0;
  .custom-tx__value-container {
    overflow: visible;
  }
  .custom-tx__multi-value {
    flex-shrink: 1;
    .custom-tx__multi-value__label {
      white-space: wrap;
    }
    .custom-tx__multi-value__remove {
      display: none;
    }
  }
  .custom-tx__indicators::before,
  .custom-tx__input-container {
    display: none;
  }
}

.alert-container {
  position: relative;
}

.alert-number {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  top: -6px;
  right: -6px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  line-height: 16px;
  background-color: $tommy-red;
  border: 1px solid rgb(255, 177, 177);
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: white;
  z-index: 5;
}

.alert-button.has-alerts,
.alert-button.has-alerts:focus {
  color: white;
  background-color: var(--brandPrimary);
  border: 1px solid var(--brandPrimary);
  box-shadow: none;
}

.alert-button:focus {
  color: black;
  background-color: #e6e6e6;
  box-shadow: none;
}

.alert-container .dropdown-menu-right {
  width: calc(100vw - 2rem);
  max-width: 350px;
  padding: 0;
  top: 8px !important;
  border-radius: $box-radius;
  box-shadow: $box-shadow;
  .alerts-content {
    padding: 0.6rem 0.75rem 0.5rem 0.75rem;
    .alerts-title,
    .alert-categories {
      button {
        font-size: 0.8rem;
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: #1e5ebd;
        padding: 0.2rem 0.4rem;
        transition: 0.2s border ease;
      }
    }
    .alerts-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      p {
        margin-bottom: 0.5rem;
      }
      .toggle-button {
        font-size: 0.8rem;
      }
    }
    .alert-categories {
      list-style: none;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid $border-color;
      li {
        display: inline-block;
        button {
          border-bottom: 1px solid transparent;
          margin-bottom: -1px;
          transition: 0.2s border ease;
          &.active {
            border-bottom: 1px solid #1e5ebd;
          }
        }
      }
    }
    .alerts-list {
      overflow-y: auto;
      max-height: 300px;
    }
    .alerts-message {
      font-size: 0.9rem;
      margin: 0;
      padding: 0.75rem 0.2rem 0.5rem 0.2rem;
    }
  }
}

.alert-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0.2rem;
  border-bottom: 1px solid $border-color;
  font-size: 0.9em;
  opacity: 1;
  .alert-description {
    opacity: 0.6;
    p {
      font-weight: 500;
      margin: 0;
    }
  }
  &.unread {
    .alert-description {
      cursor: pointer;
      opacity: 1;
    }
  }
  .unread-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #1e5ebd;
    margin: 0 12px;
  }
  button.button {
    font-size: 1rem;
    color: black;
    margin: 0 4px 0 8px;
    padding: 0 0.2rem;
  }
}

.alert-item > input {
  margin-bottom: 0;
  flex-shrink: 0;
}

.alert-item:last-of-type {
  border-bottom: none;
}

.nav-username {
  display: inline-block;
  margin-right: 0.75rem;
  margin-bottom: 3px;
  padding-left: 5px;
}

@media (max-width: 900px) {
  .n-menus .d-md-inline {
    display: none !important;
  }
  .custom-tx-select {
    width: auto;
    min-width: 0;
    max-width: 550px;
  }
}

@media screen and (max-width: 765px) {
  .n-support-text {
    display: none;
  }

  .nav-username {
    display: none;
  }

  .n-support-button {
    border-radius: 50%;
    padding: 10px;
    justify-content: center;
  }

  .n-support-button .n-support-icon {
    margin-right: 0;
    padding: 0 0 0 3px;
  }
}
