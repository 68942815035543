nav.main {
  --collapsedWidth: 80px;
  --expandedWidth: 190px;
  --navPadding: 0.6rem;
  --iconWidth: calc(var(--collapsedWidth) - (2 * (var(--navPadding))));
  --navToggleSize: 40px;
  --navBoxShadow: 2px 0px 5px rgba(0, 0, 0, 0.15);
  display: grid;
  width: var(--collapsedWidth);
  grid-template-rows: var(--collapsedWidth) auto var(--collapsedWidth);
  position: sticky;
  top: 0;
  padding: 0 var(--navPadding);
  box-shadow: var(--navBoxShadow);
  background-color: $section-background;
  color: $global-text;
  height: 100vh;
  z-index: 3;
  transition: 0.2s width ease;
  & > * {
    padding: var(--navPadding) 0;
    overflow: hidden;
  }
  & > .overlay {
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 100%;
    padding: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.3), rgba(128, 128, 128, 0.3));
    transition: opacity 0.3s ease;
    opacity: 0;
  }
  .logo {
    height: 10px;
    width: 0;
    opacity: 0;
    transition: 0.4s opacity ease 0.1s;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: var(--navPadding) 0;
  }
  li,
  .responsive-link {
    width: var(--iconWidth);
    max-width: 100%;
    transition: 0.2s all ease;
    a,
    button {
      display: inline-flex;
      width: var(--iconWidth);
      height: var(--iconWidth);
      background-color: transparent;
      border-radius: 5px;
      padding: 0 1.25rem;
      color: inherit;
      justify-content: center;
      align-items: center;
      border: 2px solid transparent;
      transition: 0.3s opacity ease, 0.3s background-color ease;
      .icon {
        display: inline-block;
        &.icon-log-out {
          transform: rotateZ(180deg);
        }
      }
      .label {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        width: 0;
        opacity: 0;
        transition: 1s opacity ease 0.2s;
      }
      &:hover {
        background-color: $tommy-gray-light;
        text-decoration: none;
      }
      &:focus-visible {
        border: 2px solid black;
        outline: none;
      }
      &.active {
        color: white;
        background-color: var(--brandPrimary);
        &:hover {
          background-color: var(--brandPrimary);
        }
      }
    }
    .username {
      .label {
        text-align: left;
        span {
          display: block;
        }
        & span:last-child {
          font-size: 0.75rem;
          margin-top: -2px;
          opacity: 0.5;
        }
      }
    }
  }
  & > .middle {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
  }
  & > .top {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    .nav-toggle {
      width: var(--navToggleSize);
      height: var(--navToggleSize);
      border-radius: var(--navToggleSize);
      text-align: center;
      font-size: 1.2rem;
      position: absolute;
      background-color: $section-background;
      i {
        display: none;
      }
      .uil-bars {
        display: inline;
      }
    }
  }
  & > .bottom {
    border-top: 1px solid #eee;
  }
  &.expanded {
    width: var(--expandedWidth);
    ~ main > nav {
      padding-left: 30px;
    }
    .logo {
      display: inline-block;
      width: auto;
      height: 100%;
      opacity: 1;
    }
    li,
    .responsive-link {
      width: 100%;
      a,
      button {
        width: 100%;
        justify-content: flex-start;
        .icon {
          margin-right: 0.75rem;
        }
        .label {
          width: auto;
          opacity: 1;
        }
      }
    }
    .top {
      .nav-toggle {
        margin-top: -3px;
        right: -20px;
        background-color: #cacacd;
        .uil-bars {
          display: none;
        }
        .uil-angle-left {
          display: inline;
        }
      }
    }
  }
}

//Need to style this separately as it exists in another component. Will only display on mobile
.nav-mobile-button {
  --navToggleSize: 40px;
  width: var(--navToggleSize);
  height: var(--navToggleSize);
  border-radius: var(--navToggleSize);
  text-align: center;
  font-size: 1.2rem;
  background-color: $section-background;
  display: none;
  i {
    display: none;
  }
  .uil-bars {
    display: inline;
  }
}

@media screen and (max-width: 700px) {
  .app-main {
    overflow-x: hidden;
    nav.main {
      margin-left: calc(var(--collapsedWidth) * -1);
      box-shadow: none;
      &.expanded {
        ~ main > nav {
          padding-left: $main-padding;
        }
        margin-left: 0;
        box-shadow: var(--navBoxShadow);
        & > .overlay {
          position: absolute;
          width: 100vw;
          opacity: 1;
        }
      }
      .nav-toggle {
        display: none;
      }
    }
    main {
      min-width: 100vw;
    }
    .n-nav {
      padding: 1rem;
      gap: 0.6rem;
      .nav-mobile-button {
        display: inline-block;
      }
    }
  }
  .redux-toastr {
    .top-right {
      top: 90px;
      min-width: auto;
    }
  }
}
