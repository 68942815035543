@import './variables.scss';

.submit-user-button {
  height: 3rem;
  padding: 0 1.5rem;
  border-radius: 6px;
  transition: $color-transition;
  background-color: white;
  color: $create-user-blue;
  border: 2px solid $create-user-blue;

  &:disabled {
    background-color: white;
    color: lighten($color: $create-user-blue, $amount: 20);
    border-color: lighten($color: $create-user-blue, $amount: 20);
  }

  &:enabled {
    &:hover {
      background-color: $create-user-blue;
      color: white;
    }
  }

  @media (max-width: 767px) {
    flex: 1;
    padding: 0;
  }
}
