.one-in-six-grid {
  justify-content: unset !important;
  grid-column: 1/6 !important;
  display: flex !important;
  flex-direction: row !important;
  margin-top: 0.35rem !important;
}

.detail-header-container {
  container-type: inline-size;
}

.detail-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 2rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 15px;

  .detail-name {
    grid-column: 1/5;
    font-size: 1.5rem;
  }
  .detail-subTitle {
    grid-column: 1/5;
    white-space: normal;
    word-wrap: break-word;
  }

  .one-in-six-grid {
    grid-column: 1/5 !important;
  }

  .detail-status {
    .button {
      margin: 0;
    }
    .detail-status-pill {
      background: yellow;
      box-shadow: 0px 2px 4px -1px;
      padding: 10px;
      border-radius: 25px;
      text-transform: unset;
      text-align: center;
      width: fit-content;
    }
  }

  .detail-url {
    font-size: 14px;
    overflow: hidden;
  }

  .detail-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    grid-column: 5/6;

    .detail-action {
      margin-right: 5px;
    }
  }

  div,
  button {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    align-self: start;

    label {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: normal;
    }
  }
}

.detail-header.inventory-detail {
  grid-template-columns: repeat(4, 1fr);

  .detail-name {
    grid-column: 1/5;
    font-size: 34px;
  }

  .detail-url {
    grid-column: 1/4;
  }

  .detail-actions {
    grid-column: 4/5;
  }
}

@container (max-width: 600px) {
  .detail-header {
    grid-template-columns: repeat(2, 1fr) !important;

    .detail-name {
      grid-column: 1/3 !important;
    }

    .detail-status {
      grid-column: 2/3 !important;
      grid-row: 1/2 !important;
    }

    .detail-url {
      grid-column: 1/3 !important;

      a {
        align-self: start;
      }
    }

    .detail-actions {
      justify-content: center;
      grid-column: 2/3 !important;
    }
  }
}

@media (max-width: 595px) {
  .detail-header {
    grid-template-columns: repeat(2, 1fr) !important;

    .detail-name {
      grid-column: 1/3 !important;
    }

    .detail-status {
      grid-row: 1/2;
      grid-column: 2/3;

      .detail-status-pill {
        font-size: 16px;
        padding: 6px;
      }
    }

    .detail-url {
      grid-column: 1/3;
    }

    .detail-actions {
      grid-column: 1/3 !important;
      justify-content: center;

      button {
        flex: 1;
        align-items: center;
      }
    }

    div {
      align-items: flex-start;
    }
  }
}

.claim-detail-info {
  margin-bottom: 1rem;

  .row {
    .item {
      margin-bottom: 1rem;
    }
  }

  .row + .row {
    margin-top: 1rem;
  }
}

.content-container {
  .nav-link {
    border: none;
  }

  .nav-tabs {
    border-bottom: 3px solid #e6e6ea;
  }

  .nav-item {
    border-radius: 3px 3px 0 0;
    margin-bottom: 0;
    border-left: 1px solid #e6e6ea;
    border-top: 1px solid #e6e6ea;
    border-right: 1px solid #e6e6ea;
    font-size: 12px;

    &:not(:first-child) {
      margin-left: 4px;
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button,
    a {
      width: 100%;
      outline: none;
      background: $tommy-gray-light;
      border-radius: 0;
      text-align: center;
      color: black;

      &.active {
        background: #e6e6ea;
      }
    }
  }

  @media (max-width: 775px) {
    .nav-item {
      flex: 1;
    }
  }

  .content {
    padding: 24px 0 46px 0;
  }

  .media-content {
    display: flex;
    flex-direction: column;

    .rewash-section {
      overflow-y: scroll;
      max-height: 1000px;
    }

    .upload-section {
      .upload-preview-wrapper {
        display: flex;
        justify-content: center;

        .upload-preview {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 200px;
          text-align: center;
          margin: 15px 0;
        }
      }

      .upload-description {
        margin-top: 20px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;

        .upload-description-field {
          width: 100%;
          height: 104px;
          border-radius: 2px;
          border: solid 1px #e6e6ea;
          background: $tommy-gray-light;
          resize: none;
        }

        .upload-save-button {
          width: 76px;
          height: 28px;
          border-radius: 3px;
          background-color: #e6e6ea;
          margin-top: 10px;

          &:hover {
            background-color: #c4c4c4;
          }
        }
      }
    }

    .mouse-pointer {
      cursor: pointer;
    }

    .media {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-auto-rows: 188px;
      grid-gap: 16px;
      margin-top: 25px;

      .media-item {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: $box-radius;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
        transform: translate(0);

        .media-item-description {
          padding: 10px;
          font-size: 12px;
          text-align: center;
          width: 100%;
        }

        .no-image {
          margin: 0;
          margin-top: -6px;
          color: #000d21;
          opacity: 0.1;
          i {
            font-size: 2.5rem;
          }
        }

        .media-item-container {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          height: 150px;

          img,
          video {
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .media-item-delete-button {
          position: absolute;
          display: flex;
          right: 5px;
          top: 5px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background: #e13e3d;
          color: #fdfdfd;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
            0 1px 5px 0 rgba(0, 0, 0, 0.2);

          &:hover {
            background: #b73737;
            cursor: pointer;
          }
        }
      }
    }

    .media.rewash-section {
      grid-auto-rows: auto;
      .card {
        border: none;
        border-radius: $box-radius;
        align-self: stretch;
        .media-item {
          height: 120px;
          flex-direction: row;
          box-shadow: none;
          background-color: $tommy-gray-light;
          border: 1px solid $border-color;
          border-radius: $box-radius $box-radius 0 0;
          &.no-border {
            border: none;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            opacity: 0.7;
            transition: 0.2s all ease;
          }
        }
        .details {
          border: 1px solid $border-color;
          border-top: none;
          border-radius: 0 0 $box-radius $box-radius;
          padding: 0.75rem 1rem;
          flex-grow: 1;
          p {
            font-size: 0.9rem;
            margin: 0;
            line-height: 1.6em;
          }
        }
      }
      .card:hover .media-item img {
        opacity: 1;
      }
    }

    @media (max-width: 1000px) {
      .media {
        grid-template-columns: 1fr 1fr;
      }
    }

    @media (max-width: 775px) {
      .media {
        grid-template-columns: 1fr !important;
      }
    }

    .media-enlarged-img-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.9);

      .media-enlarged-img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .media-enlarged-photo-description {
          color: #ffffff;
          padding: 50px;
        }

        img {
          height: auto;
          width: auto;
          max-width: 90%;
          max-height: 90%;
        }
      }
    }
  }

  @media (max-width: 1125px) {
    .media-content {
      flex-direction: column;

      .upload-section {
        width: 100%;
      }

      .media {
        margin-top: 25px;
        grid-gap: 14px;
      }
    }
  }
}

.react-file-reader {
  width: 145px;
}

.file-reader {
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  background: $gray-light;
  width: fit-content;

  &:hover {
    background: #666666;
  }

  .file-reader-button {
    display: flex;
    align-items: center;
  }
}

.upload-button {
  display: flex;
  align-items: center;

  .upload-plus {
    font-family: Lato;
    font-size: 27px;
    font-weight: 900;
    line-height: normal;
    color: #ffffff;
    margin-right: 5px;
  }

  .upload-text {
    color: #ffffff;
  }
}

.form-file-preview {
  display: flex;
  align-items: flex-start;

  img,
  .badge {
    height: auto;
    width: auto;
    max-width: 65%;
    max-height: 100%;
    border-radius: 5px;
    margin-right: 25px;
  }
}

.inventory {
  .page-action-buttons {
    text-align: right;
  }
}

.inventory-details-wrapper {
  padding-top: 1rem;
}

@media (max-width: 505px) {
  .form-file-preview {
    .upload-button {
      font-size: 10px !important;

      .upload-plus {
        font-size: 10px;
      }
    }
  }
}

.issuesite-approval {
  margin-bottom: 1rem;

  .approval-title {
    display: inline-block;
    margin-bottom: 5px;
  }

  .btn-accept {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;

    &:hover {
      background-color: darken($color: #28a745, $amount: 4%);
    }
  }

  .icon-warning {
    font-size: 40px;
    color: #d9534f;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .issuesite-approval {
    .approval-action {
      margin-bottom: 0;
    }
  }
}
