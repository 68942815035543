$gray-light: #4a4a4a;
$tommy-red: #af302c;
$tommy-red-bright: #ff0000;
$tommy-blue: hsl(224, 100%, 50%);
$washclub-blue: #2f85d6;
$tommy-gray-light: #f6f6f8;
$green-hue: 140deg;
$checkmark-green: hsl($green-hue, 75%, 45%);
$light-red: hsl(0deg, 94%, 92%);
$light-yellow: hsl(55deg, 100%, 90%);
$light-blue: hsl(207deg, 100%, 95%);
$light-green: hsl(146deg, 72%, 83%);

$global-text: black;

//Global border radius for most elements
$box-radius: 4px;

//Global brand color (red for Tommys, blue for Wash Club, etc)
$brand-primary: $tommy-red;

:root {
  --brandPrimary: #{$tommy-red};
  --brandText: #{$tommy-red};
  --brandPrimaryHover: #{desaturate(lighten($tommy-red, 10), 5)};
  --brandGradient: linear-gradient(277.91deg, #{$tommy-red} 30.7%, #d3181b 72.54%, #e81111 99.1%);
}

//$section-background: #f6f6f8;
$section-background: white;
$section-padding: 1.25rem;
$main-padding: 1rem;
$border-color: #ced4da;

$shadow-intensity: 0.15;
$box-shadow: 0px 2px 5px rgba(0, 0, 0, $shadow-intensity);
$box-shadow-tabs: 0px 4px 5px rgba(0, 0, 0, $shadow-intensity);

$login-button-red: linear-gradient(277.91deg, $tommy-red 30.7%, #d3181b 72.54%, #e81111 99.1%);
$tommy-text-red: $tommy-red;
$clear-fields-red: $tommy-red-bright;
$clear-fields-disabled: $tommy-gray-light;
$archive-user-red: $tommy-red-bright;
$create-user-blue: $tommy-blue;
$next-page-blue: $tommy-blue;
$confirm-blue: $tommy-blue;
$cancel-red: $tommy-red-bright;
$remove-site-button: $tommy-red;

$color-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
