.slide-container {
  display: flex;
  & > * {
    transition: 0.5s all ease;
  }
  & > *:not(.slider) {
    flex: 1 1 auto;
    width: auto;
    min-width: 0;
    overflow: hidden;
  }
  .slider {
    flex-basis: 0%;
    overflow-x: hidden;
    opacity: 0;
    & > * {
      opacity: 0;
      transition: 0.1s all ease;
    }
  }
  &.active {
    .slider {
      flex-basis: auto;
      opacity: 1;
      flex-shrink: 0;
      flex-grow: 1;
      & > * {
        opacity: 1;
        transition: 0.5s 0.2s all ease;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .slide-container {
    /*max-height: 600px;
    overflow-y: auto;*/
    &.active {
      .slider {
        flex-basis: 100% !important;
        padding-left: 0 !important;
      }
    }
  }
}
