@import 'variables';

.landing-page-footer {
  text-align: center;
  margin: auto 0 0 0;

  a {
    color: var(--brandText);
  }
}
