.parts-container {
  display: flex;
  justify-content: space-between;
}

.parts-picker-container {
  display: flex;
  height: fit-content;

  button {
    margin-right: 5px;
    border-radius: 0.25rem;
  }
}

.parts-picker {
  transform: translate(0);

  & > div > .suggestions-container {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 815px) {
  .parts-container {
    flex-direction: column;
  }

  .parts-picker {
    width: 100%;
  }
}
