.visual-scroll {
  --elementHeight: 10px;
  --shadowOpacity: 0.2;
  overflow: auto;
  position: relative;
  .scroll-container {
    position: sticky;
    float: left;
    width: 100%;
    height: 100%;
    padding: 0;
    inset: 0;
    .scroll {
      position: absolute;
      width: 100%;
      height: var(--elementHeight);
      top: 0;
      left: 0;
      background: radial-gradient(
        50% var(--elementHeight) at center top,
        rgba(0, 0, 0, var(--shadowOpacity)),
        rgba(0, 0, 0, 0)
      );
      transition: 0.2s all ease;
      &.bottom {
        top: auto;
        bottom: 0;
        transform: rotateZ(180deg);
      }
      &.left,
      &.right {
        top: auto;
        width: var(--elementHeight);
        background: radial-gradient(
          var(--elementHeight) 50% at left center,
          rgba(0, 0, 0, var(--shadowOpacity)),
          rgba(0, 0, 0, 0)
        );
        height: 100%;
      }
      &.right {
        left: auto;
        right: 0;
        transform: rotateZ(180deg);
      }
    }
  }
  .content {
    position: absolute;
    inset: 0;
  }
}
