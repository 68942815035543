// enable flexbox for bootstrap
$enable-flex: true;

// overrides
$brand-success: #b0312d;
$btn-border-radius: 0;

// header sizes
$h1-font-size: 1.75rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1rem;
$h5-font-size: 0.8rem;
$h6-font-size: 0.6rem;

$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: #d2d2d2;
$nav-tabs-link-active-hover-color: #292b2c;
$nav-tabs-link-active-hover-bg: transparent;
$nav-tabs-link-active-hover-border-color: #d2d2d2;
$nav-tabs-link-hover-border-color: #d2d2d2;
$nav-tabs-border-color: #d2d2d2;
