$green-hue: 140deg;
$blue-hue: 211deg;
$button-padding: 0.45rem 0.75rem;

.column-header.disabled {
  color: rgba(0, 0, 0, 0.5);
}

.row-in-edit {
  background-color: rgba(0, 0, 0, 0.1);
}

.task-link-card-body {
  display: flex;
  justify-content: space-between;
  max-height: 60px;
  margin-bottom: auto;
  margin-top: auto;
}

.task-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin-right: 1rem;
  }
}

.maintenance-tasks-container {
  .task-table-header {
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    & > div {
      display: flex;
    }

    .form-control {
      width: 125px;
    }
  }
  .maintenance-site-dropdown {
    min-width: 300px;
    margin-right: 8px;
    .dropdown-menu-left {
      min-width: 300px;
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 1.4rem;
      line-height: 1.6rem;
      max-width: 300px;
      text-align: left;
      background-color: rgba(0, 0, 0, 0);
      margin: 7px 8px 0 0;
      height: 50px;
      border: none;
      &:focus {
        color: black;
      }
      i {
        display: inline-block;
        margin-left: 4px;
      }
    }
    .dropdown-item {
      font-size: 1.2rem;
      padding: 0.7rem 1.5rem;
      &:hover {
        background-color: var(--brandPrimary);
        color: white;
      }
    }
    p {
      margin: 0;
      padding: 0 0.5rem;
    }
  }
  .form-group {
    padding-right: 8px;
    margin-bottom: 0;
  }
  .dt-table-scroll {
    height: calc(100vh - 305px);
    .dr-styled-checkbox:checked + label:before,
    .dr-styled-checkbox:indeterminate + label:before {
      background-color: hsla($green-hue, 76%, 45%, 1);
      border-color: hsla($green-hue, 76%, 45%, 1);
    }
    .dr-row {
      &:hover {
        background: rgba(0, 0, 0, 0) !important;
      }
      &.highlighted,
      &.highlighted:hover {
        background: hsla($green-hue, 100%, 45%, 0.1) !important;
      }
      .dr-multi-select-cell {
        background: none !important;
      }
    }
  }
  .data-table-search {
    width: 100%;
    max-width: 450px;
    background-color: white;
    border: 1px solid #ced4da;
    &:focus {
      border: 1px solid black;
      outline: none;
      box-shadow: none;
    }
  }
  .drawer-content {
    height: 100%;
  }
  .maintenance-task-detail {
    display: grid;
    grid-template-columns: minmax(0, auto) 200px;
    height: 100%;
    & > * {
      height: 100%;
      border-radius: 4px;
      border: 1px solid rgb(218, 218, 218);
      padding: 1.2rem;
    }
    .task-main {
      position: relative;
      background-color: white;
      margin-right: 0.5rem;
      .task-header {
        display: flex;
        justify-content: space-between;
      }
      .task-title {
        p {
          font-size: 0.9rem;
          margin: 0;
        }
        h2 {
          margin: 0.2rem 0 1.2rem 0;
        }
      }
      .task-tabs {
        .nav-tabs {
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
          .nav-link {
            padding: 0.5rem 0.7rem;
            white-space: nowrap;
          }
          li:nth-child(2) {
            display: none;
          }
        }
        .content {
          max-height: 515px;
          padding: 24px 0 0 0;
          overflow-y: auto;
          .task-notes-section {
            overflow: auto;
            max-height: initial;
          }
          .linked-tasks {
            padding: 0;
          }
        }
      }
      .task-footer {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 1.2rem;
        left: 1.2rem;
        right: 1.2rem;
      }
    }
    .task-details-container {
      > button {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    .task-details {
      p {
        margin: 0.7rem 0 0 0;
        font-size: 0.9rem;
      }
    }
  }
  .btn,
  .blue-button,
  .task-table-header-btn {
    font-weight: bold;
    padding: $button-padding;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.05);
    &.green {
      background-color: hsla($green-hue, 76%, 45%, 1);
      border-color: hsla($green-hue, 76%, 45%, 1);
      &:hover {
        background-color: hsla($green-hue, 76%, 40%, 1);
        border-color: hsla($green-hue, 76%, 40%, 1);
      }
    }
    &.blue {
      background-color: hsla($blue-hue, 100%, 50%, 1);
      border-color: hsla($blue-hue, 100%, 50%, 1);
      margin-right: 10px;
      &:hover {
        background-color: hsla($blue-hue, 100%, 40%, 1);
        border-color: hsla($blue-hue, 100%, 40%, 1);
      }
    }
    &.mobile-button {
      width: 100%;
      max-width: 200px;
      margin-bottom: 16px;
    }
    &:hover {
      background-color: rgb(222, 222, 225);
    }
  }
  .mobile-button {
    display: none;
  }
  .badge {
    padding: 0.25em 0.4em 0.4em 0.4em;
    &.complete {
      background-color: hsla($green-hue, 76%, 45%, 0.2);
      color: hsla($green-hue, 76%, 45%, 1);
    }
    &.incomplete {
      background-color: hsla(30deg, 100%, 45%, 0.2);
      color: hsla(30deg, 100%, 45%, 1);
    }
    &.snoozed {
      background-color: hsla($blue-hue, 100%, 45%, 0.2);
      color: hsla($blue-hue, 100%, 30%, 1);
    }
    &.overdue {
      background-color: hsla(0deg, 100%, 50%, 0.2);
      color: #be1f24;
    }
    &.preventative {
      color: white;
      background-color: orange;
    }
    &.undefined {
      color: grey;
      background-color: white;
    }
  }
}

.icon-controls {
  .task-actions-dropdown {
    display: inline-block;
    vertical-align: top;
  }
  vertical-align: top;
  button.icon {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    width: 2.5rem;
    height: 2.5rem;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0.5em;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      transition: 0.2s all ease;
    }
    &:focus {
      color: black;
    }
  }
}

.todays-task-detail {
  .page-details-content {
    margin-top: 16px;
  }
}

.task-table-header-btn {
  @extend .button-with-loader;
  margin-top: 0px !important;
  width: auto !important;
}

.sortable-column {
  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1150px) {
  .maintenance-tasks-container {
    .task-table-header {
      .maintenance-site-dropdown {
        min-width: 200px;
        .dropdown-menu-left {
          min-width: 200px;
        }
      }
      .form-control {
        width: 95px;
      }
    }
    .maintenance-task-detail {
      display: block;
      & > * {
        height: auto;
      }
      .task-main {
        height: 100%;
        .task-tabs {
          .nav-tabs {
            li:nth-child(2) {
              display: list-item !important;
            }
          }
        }
      }
      .task-details-container {
        display: none;
      }
    }
  }
}

@media screen and (max-height: 900px) {
  .maintenance-tasks-container {
    .dt-table-scroll {
      max-height: 500px;
    }
    .task-main {
      .task-tabs {
        .content {
          max-height: 295px !important;
          padding: 12px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .maintenance-tasks-container {
    .delete-task,
    .resize-task {
      display: none;
    }
    .mobile-button {
      display: inline-block;
    }
  }
}

@media screen and (max-width: 768px) {
  .maintenance-tasks-container {
    .task-table-header {
      display: block;
      & > div {
        display: block;
      }
      button,
      select {
        width: 100% !important;
        max-width: 100% !important;
      }
      .form-group {
        display: inline-block;
        width: 50% !important;
        max-width: 50% !important;
      }
      .btn.add-task,
      .button.add-task {
        width: 100% !important;
        max-width: auto !important;
      }
    }
    .data-table-search {
      width: 100% !important;
      max-width: 100% !important;
    }
    .dt-table-scroll {
      max-height: 460px;
    }
    .task-main {
      .content {
        max-height: 295px !important;
        padding: 12px 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .maintenance-tasks-container {
    .task-main {
      .task-title {
        h2 {
          font-size: 1.3rem;
        }
      }
      .task-tabs {
        .content {
          max-height: 250px !important;
        }
      }
      .btn.mobile {
        max-width: 500px;
      }
    }
  }
  .task-dialog {
    & > .modal-content {
      .task-form-row {
        grid-template-columns: 1fr;
      }
    }
  }
}
