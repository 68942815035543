.add-purchase-item {
  float: right !important;
}

.form-purchase-order-totals {
  font-weight: bold;
  text-align: end;
}

.expenses-upload {
  .table-filters {
    margin-top: 0;
    label {
      font-weight: normal;
    }
  }
  .selected-form {
    .upload-form {
      max-height: 550px;
      overflow-y: auto;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin: 1.5rem 0;
      padding: 1.5rem 0.75rem 1.5rem 0;
      .form-group.file-upload:last-child {
        margin-bottom: 0;
      }
    }
    .form-row {
      justify-content: space-between;
      margin: 0;
      .form-group {
        label {
          display: block;
        }
      }
    }
    button.confirm {
      max-width: 400px;
      margin-left: auto;
    }
  }
  .p-l-upload {
    & > p:first-child {
      margin-bottom: 0.75rem;
    }
    .p-l-template-link {
      margin-bottom: 1.5rem;
    }
  }
  .vending-sales {
    .form-row {
      padding: 0.75rem 0 0.9rem 0;
      align-items: center;
      > p {
        display: flex;
        align-items: center;
        margin: 0;
      }
      > div {
        display: flex;
        gap: 1rem;
        .vending-totals {
          width: 100px;
        }
      }
      .form-group {
        margin: 0;
      }
    }
    .form-row:not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

@media (max-width: 768px) {
  .purchase-order-table-buttons {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .form-group {
    .label {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .filename {
      display: none;
    }
  }
}
