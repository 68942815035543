@import './variables.scss';

.modal-xl {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-height: 100% !important;
  padding: 1rem;
  height: 100%;
}

.modal-body {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow-y: auto;
}

.user-modal-content {
  min-height: 650px;
  max-height: 100%;
}

.checkbox-mark {
  margin: 0 !important;
}

.badge-container {
  padding: 0.5rem 1rem 0 1rem;
}

.badge.bg-red {
  background: $tommy-red-bright;
  color: #ffffff;
}
